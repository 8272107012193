.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #141414;
  color: #f5f4f4;
  cursor: none;
}

.List-item {
  flex:1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0rem;
  border-bottom: 0.1rem;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  list-style: none;
  width: 95%;
  font-size: x-large;
  display: inline-block;
  position: relative;
  
}

.List-item::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -1;
  left: 0;
  background-color: #f57948;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  border: none;

}

.List-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  text-decoration: none;
  border: none;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imageLink {
  transition: transform .35s;
}

.imageLink:hover {
  transform: scale(1.1);
}

.imageLink:hover::after {
  opacity: 1;
  border-radius:20;

}

.imageLink::after {
  content: "Go to GitHub Repo";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  background: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  opacity: 0;
  transition: opacity .35s;
  border-radius: 1%;
}

.link {
  display: inline-block;
  position: relative;
  color: #f57948;
  text-decoration: none;
}

.link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f57948;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.pill{
  background-color:#5492f5;
  border-radius:40px;
  text-align:center;
  padding-bottom:3px;
  width:25%;
  margin-left:1%
}

.cursor{
  display:none;
  width: 7px;
  height: 7px;
  background-color: whitesmoke;
  margin-left: 96.5px;
  margin-top: 96.5px;
  transition: all 0.3s ease;
  transition-property: background, transform;
  transform-origin: center center;
  z-index: 20000;
  pointer-events: none;
}

.gradient{
  width: 200px;
  height: 200px;
  background-image: radial-gradient(circle closest-side, rgba(67,113,110,1) 0%, rgba(208, 150, 73, 0.724) 50%, rgba(161, 67, 12, 0.615) 80%,rgba(161, 67, 12, 0.235) 90%, rgba(161, 67, 12, 0) 100%);
  display:none;
  position: fixed;
  transition: all 0.3s ease;
  transition-property: background, transform;
  transform-origin: center center;
  z-index: 20000;
  pointer-events: none;

}

.verticalList{
  flex:1;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0rem;
  border-bottom: 0rem;
  border-left: 0.1rem;
  border-right: 0;
  border-style: solid;
  list-style: none;
  width: 95%;
  height: 40rem;
  font-size: large;
  display: inline-block;
  position: relative;
  margin-top: 2rem;
  transition: flex 2s, color 1.25s ease-out;
  border-color: white;
  color: rgba(255, 252, 252, 0);
}



.expandedList{
  flex:5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0rem;
  border-bottom: 0rem;
  border-left: 0.2rem;
  border-right: 0;
  border-color: white;
  border-style: solid;
  list-style: none;
  width: 95%;
  height: 40rem;
  font-size: large;
  display: inline-block;
  position: relative;
  margin-top: 2rem;
  color: rgb(255, 252, 252);
  border-color: #f57948;
}



.numTitles{
  color: white;
  margin-left:1rem;
  font-family: Baskerville;
  font-size:4rem;
  margin-top:0;
  margin-bottom:0;
}

.pillGroup{
  flex-direction: row;
  display: flex;
  align-self: center;
  width: 90%;
  transition: opacity 2s, width 1s;
  margin-left: 1rem
}