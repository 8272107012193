.header-background{
    background-color: rgba(152, 152, 152, 0.15);
    position: sticky;
    top: 0;
    backdrop-filter: blur(7px);
    z-index: 10000;
    height: 4rem;
}
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 2.5%;
    margin-right: 5%;
    border-top: 0rem;
    border-bottom: 0.06rem;
    border-left: 0;
    border-right: 0;
    border-color: #f5f5f5;
    border-style: solid;
    height: 100%;
}

.link {
    display: inline-block;
    position: relative;
    color: #f5f5f5;
    text-decoration: none;
    font-size: 1.2rem;
}
  
.link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f57948;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}